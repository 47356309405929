import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Currency} from '../../../api/document-pipeline';

@Component({
  selector: 'app-currency-picker',
  templateUrl: './currency-picker.component.html',
})
export class CurrencyPickerComponent implements OnInit {
  @Input()
  private Currencies?: { [key: string]: Currency; };

  public currencyList: {
    Label: string
    Code: string
    Symbol: string
  }[];

  public currencyValue: string;

  @Input()
  public clearable = false;

  @Output()
  public currencyChange = new EventEmitter<string>();

  @Input()
  get currency() {
    return this.currencyValue;
  }

  set currency(val) {
    const emitChange = this.currencyValue !== undefined && (!this.currencyValue || this.currencyValue !== val);
    this.currencyValue = val;
    if (emitChange) {
      this.currencyChange.emit(this.currencyValue);
    }
  }

  constructor() { }

  ngOnInit() {
    this.currencyList = [];
    const currencyCodes = Object.keys(this.Currencies);
    currencyCodes.forEach(code => {
      if (this.Currencies[code].Show) {
        this.currencyList.push({
          Label: this.Currencies[code].Title,
          Code: code,
          Symbol: this.Currencies[code].Symbol,
        });
      }
    });
  }

  filterCurrency(filter: string, currency: {
    Label: string
    Code: string
    Symbol: string
  }) {
    /** Returns whether this currency matches a given search string. */
    return (currency.Label.toLowerCase().includes(filter.toLowerCase())
      ||
      currency.Code.toLowerCase().includes(filter.toLowerCase())
      ||
      currency.Symbol.toLowerCase().includes(filter.toLowerCase())
    );
  }
}
