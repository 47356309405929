import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IMAGEVIEWER_CONFIG, ImageViewerConfig, createButtonConfig} from '@hallysonh/ngx-imageviewer';
import {FileUtils} from '../../../helpers/file-utils';
import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {PdfJsViewerComponent} from 'ng2-pdfjs-viewer';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  width: 600,
  height: 800,
  loadingMessage: ' ',
  buttonStyle: {
    iconFontFamily: 'fontawesome', // font used to render the button icons
    alpha: 0.5, // buttons' transparence value
    hoverAlpha: 0.7, // buttons' transparence value when mouse is over
    bgStyle: '#000000', //  buttons' background style
    iconStyle: '#ffffff', // buttons' icon colors
    borderStyle: '#000000', // buttons' border style
    borderWidth: 0 // buttons' border width (0 == disabled)
  },
  bgStyle: 'transparent',
  zoomOutButton: createButtonConfig(String.fromCharCode(0xf010), 'Zoom out', 0),
  zoomInButton: createButtonConfig(String.fromCharCode(0xf00e), 'Zoom in', 1),
  rotateLeftButton: createButtonConfig(String.fromCharCode(0xf0e2), 'Rotate left', 2),
  rotateRightButton: createButtonConfig(String.fromCharCode(0xf01e), 'Rotate right', 3),
  resetButton: createButtonConfig(String.fromCharCode(0xf0b2), 'Reset zoom', 4)
};

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class FileViewerComponent implements OnDestroy, OnChanges {
  @ViewChild('fileContainer') fileContainer: ElementRef;
  @ViewChild('imageViewer') imageViewer: any;
  @ViewChild('pdfView') pdfView: PdfJsViewerComponent;
  @Input() blob: Blob;
  @Input() filename: string;
  @Input() type: string;
  @Input() viewerType = 'image';
  @Input() fileCount: number;
  @Input() currentFile: number;
  @Input() isOCR: boolean;

  url: string;
  imageUrl: SafeUrl;
  pdfFilename: string;
  isPDF = false;

  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateContent();
  }

  ngOnDestroy() {
    if (this.url && this.url !== '') {
      URL.revokeObjectURL(this.url);
    }
  }

  getWrapperStyle(): SafeStyle {
    if (this.isOCR && this.type === 'document' && this.isPDF) {
      return this.domSanitizer.bypassSecurityTrustStyle('display: block; width: 100%; height: 600px');
    }
    return this.domSanitizer.bypassSecurityTrustStyle('display: block; width: 100%; height: calc(100% - 61px)');
  }

  updateContent() {
    if (this.url && this.url !== '') {
      URL.revokeObjectURL(this.url);
    }

    this.url = URL.createObjectURL(this.blob);
    this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);

    if (this.filename && this.filename.slice(-4).toLowerCase() === '.pdf') {
      this.pdfFilename = this.filename.substr(0, this.filename.length - 4);
    } else {
      this.pdfFilename = this.filename;
    }

    if (this.blob.type === 'application/pdf' || this.blob.type === 'application/x-pdf' || this.blob.type === 'x-application/apple-pdf') {
      this.isPDF = true;

      // Workaround when pdfView is already loaded. They did not implement ngOnChanges.
      if (this.pdfView) {
        this.pdfView.pdfSrc = this.url;
        this.pdfView.downloadFileName = this.pdfFilename;
        this.pdfView.refresh();
      }
    }
  }

  downloadFile() {
    FileUtils.downloadFile(window, this.blob, this.filename, this.url);
  }
}

