// tslint:disable

import {AuthManager} from './auth';

/**
 * DocumentPipeline API
 * # Introduction The DocumentPipeline Webservice API is a REST webservice to create document pipelines by Klippa.  The service replies are JSON only.  The service base URL is https://api.document-pipeline.klippa.com/api/v1. The service base URL for the test environment is https://api.test.document-pipeline.klippa.com/api/v1, we test experimental features there.  # Authentication The service requires the following headers to be set: Header | Description | --- |--- |   X-Auth-Key  |  The session key provided on login. |   X-Auth-Timestamp  |   The current unix timestamp. Make sure your time is synced, it allows a max difference of one hour. |   X-Auth-Nonce  |   A unique string that can only be used once (in php you can use uniqid to generate one), max length = 16 and format = a-zA-Z0-9 (due to technical reasons). |   X-Auth-Signature  |   The HMAC signature in hex representation of the request, you can generate the hmac with HMAC_SHA512(timestamp + nonce, secret). |  The key and secret are given on login.
 *
 * OpenAPI spec version: [VERSION-PLACEHOLDER]
 * Contact: jeroen@klippa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfigurationParameters {
    apiKey?: string | ((name: string) => string);
    username?: string;
    password?: string;
    accessToken?: string | ((name: string, scopes?: string[]) => string);
    basePath?: string;
    baseOptions?: any;
    authManager?: AuthManager;
}

export class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey?: string | ((name: string) => string);
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username?: string;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password?: string;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken?: string | ((name: string, scopes?: string[]) => string);
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath?: string;
    /**
     * base options for axios calls
     *
     * @type {any}
     * @memberof Configuration
     */
    baseOptions?: any;

    public authManager?: AuthManager;

    constructor(param: ConfigurationParameters = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
        this.authManager = param.authManager;
    }
}
