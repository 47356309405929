import { BrowserModule } from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/unauthenticated/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BaseComponent } from './base/base.component';
import { AuthenticationComponent } from './pages/unauthenticated/authentication.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {reducers} from './reducers';
import {StoreModule} from '@ngrx/store';
import {MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CustomHandler, CustomParser} from './util/i18n';
import {SortablejsModule} from 'angular-sortablejs';
import {UiModule} from './shared/ui/ui.module';
import {LocalStorageModule} from 'angular-2-local-storage';
import {CommonLayoutComponent} from './layouts/common-layout.component';
import {AuthenticatedComponent} from './pages/authenticated/authenticated.component';
import * as fromUser from './modules/user/models/user.reducer';
import {NgSelectModule} from '@ng-select/ng-select';
import {DirectivesModule} from './directives/directives.module';
import {ClassifyComponent} from './pages/authenticated/classify/classify.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseComponent,
    AuthenticationComponent,
    CommonLayoutComponent,
    AuthenticatedComponent,
    ClassifyComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      parser: {provide: TranslateParser, useClass: CustomParser},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: CustomHandler},
    }),
    LocalStorageModule.forRoot({
      prefix: 'ocr',
      storageType: 'localStorage'
    }),
    AppRoutingModule,
    ReactiveFormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    StoreModule.forRoot(reducers),
    StoreModule.forFeature('user', fromUser.userReducer),
    FormsModule,
    UiModule,
    SnotifyModule,
    NgSelectModule,
    DirectivesModule,
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    BaseComponent.injector = this.injector;
  }
}
