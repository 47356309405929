import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-percentage-input',
  templateUrl: './percentage-input.component.html',
})
export class PercentageInputComponent implements OnInit {
  @Input() public percentage: number;
  @Input() public disabled = false;
  @Output() public percentageChange = new EventEmitter<number>();
  public inited = false;

  get percentageValue() {
    if (this.percentage) {
      return this.percentage / 100;
    }
    return undefined;
  }

  set percentageValue(val) {
    const newVal = Math.round(val * 100);
    this.percentage = newVal;
    if (this.inited) {
      this.percentageChange.emit(newVal);
    }
    this.inited = true;
  }

  ngOnInit() {
    this.percentageValue = this.percentage / 100;
  }
}
