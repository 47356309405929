import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import ld from 'lodash';
import {Currency, KlippaOCRReceiptVAT} from '../../../api/document-pipeline';

@Component({
  selector: 'app-vat-input',
  templateUrl: './vat-input.component.html',
})
export class VATInputComponent implements OnInit {
  @Input()
  public currency: string;
  @Input()
  public totalAmount = 0;
  @Input()
  public items: Array<KlippaOCRReceiptVAT>;

  @Input()
  private Currencies?: { [key: string]: Currency; };

  @Output() public newValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  public percentage = 0;
  public amount = 0;

  constructor() { }

  ngOnInit() {
  }

  percentageChange(item: KlippaOCRReceiptVAT) {
    if (this.totalAmount <= 0) {
      return;
    } else if ((typeof(item.amount) !== 'undefined' && item.amount !== 0) || item.percentage <= 0) {
      return;
    }

    let remaining = this.totalAmount;
    this.items.forEach(i => {
      let amount = i.amount;
      if (typeof(amount) === 'undefined') {
        amount = 0;
      }
      remaining -= ld.round(amount / i.percentage * (10000 + i.percentage), 0);
    });
    const VATAmount = ld.round(remaining / (10000 + item.percentage) * item.percentage, 0);
    if (VATAmount > 0) {
      item.amount = VATAmount;
      this.newValue.emit(true);
    }
  }

  amountChange() {
    this.newValue.emit(true);
  }

  removeItem(i: number) {
    this.items.splice(i, 1);
  }

  addItem() {
    this.items.push(({amount: 0, percentage: 0}));
  }
}
