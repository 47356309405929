import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticatedComponent} from '../authenticated.component';
import {_} from '../../../util/i18n';
import {
  TransformRGSClassifierGetJobOptionResponse,
  TransformRGSClassifierGetJobResponse
} from '../../../api/document-pipeline';
import {Logout} from '../../../modules/user/models/user.actions';
import {debounceTime, switchMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-classify',
  templateUrl: './classify.component.html',
  styleUrls: ['./classify.component.scss']
})
export class ClassifyComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
  public job: TransformRGSClassifierGetJobResponse;
  protected files = [];
  public jobCount = 0;
  protected statInterval;

  public SelectedOption = null;
  public SelectOptions?: Array<TransformRGSClassifierGetJobOptionResponse>;
  public SelectOptionsFiltered?: TransformRGSClassifierGetJobOptionResponse[];
  public SelectOptionsTypeahead = new EventEmitter<string>();

  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;

  public hideSubmitButtons = false;
  public disableSubmitButtons = false;
  public loading = false;

  constructor(private http: HttpClient) {
    super();

    this.app = {
      layout: {
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }

    this.statInterval = setInterval(() => {
      this.loadStats();
    }, 15000);

    this.SelectOptionsTypeahead
      .pipe(
        debounceTime(100),
        switchMap(term => {
          const filteredOptions = [];
          const lowerTerm = term.toLowerCase();
          const words = lowerTerm.split(' ');

          let count = 0;
          for (let option of this.SelectOptions) {
            // Limit to 100 options.
            if (count === 100) {
              break;
            }

            const lowerKey = option.Key.toLowerCase();
            const lowerValue = option.Value.toLowerCase();

            // Check if all words are in either key or value.
            let matchedKey = true;
            let matchedValue = true;
            for (let word of words) {
              if (!lowerKey.includes(word)) {
                matchedKey = false;
              }

              if (!lowerValue.includes(word)) {
                matchedValue = false;
              }

              // Break when both words didnt match.
              if (!matchedKey && !matchedValue) {
                break;
              }
            }

            // Add when either key or value matches.
            if (matchedKey || matchedValue) {
              count++;
              filteredOptions.push(option);
            }
          }

          return Promise.resolve(filteredOptions);
        })
      )
      .subscribe(items => {
        this.SelectOptionsFiltered = items;
      }, (err) => {
        this.SelectOptionsFiltered = this.SelectOptions;
      });

    this.loadStats();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.statInterval) {
      clearTimeout(this.statInterval);
    }
  }

  loadJob() {
    this.loading = true;
    this.documentPipelineService.transform.transformRGSClassifierGetJobInput().then(job => {
      this.reset();
      this.setRGSClassifyJob(job.data.Data);
    }).catch(e => {
      if (typeof e.response.data === 'object' && e.response.data.ErrorCode === 500001 && e.response.data.ErrorMessage === 'No companies found') {
        this.notificationService.error('You don\'t have access to any company to perform RGS Classify jobs. You have been logged out.', null, {
          timeout: 0,
        });
        this.logoutUser();
        return;
      }

      this.reset();
      this.notifications.handleAPIError(e);
    });
  }

  setRGSClassifyJob(job: TransformRGSClassifierGetJobResponse) {
    this.disableSubmitButtons = true;
    setTimeout(() => {
      this.disableSubmitButtons = false;
    }, 2000);

    this.loadStats();
    this.job = job;
    this.SelectOptions = job.Options;

    // Set initial list.
    const filteredOptions = [];
    let count = 0;
    for (let option of this.SelectOptions) {
      // Limit to 100 options.
      if (count === 100) {
        break;
      }

      count++;
      filteredOptions.push(option);
    }

    this.SelectOptionsFiltered = filteredOptions;

    this.files = [];
    this.job.Files.forEach((ocrFile, index) => {
      if (ocrFile.URL && ocrFile.URL !== '') {
        this.http.get(ocrFile.URL, {
          responseType: 'blob',
        }).toPromise().then(res => {
          let type = 'document';
          if (res.type === 'image/png' || res.type === 'image/gif' || res.type === 'image/jpg' || res.type === 'image/jpeg') {
            type = 'image';
          }
          this.files[index] = {type, blob: res, filename: ocrFile.Filename};
        }).catch(e => {
          this.notifications.handleAPIError(e);
        });
      } else {
        this.documentPipelineService.transform.transformRGSClassifierGetJobFilesRawInput(this.job.Job.ID, ocrFile.Phase, ocrFile.Filename,  {
          responseType: 'blob',
        }).then(result => {
          let type = 'document';
          if (result.headers['content-type'] === 'image/png' || result.headers['content-type'] === 'image/gif' || result.headers['content-type'] === 'image/jpg' || result.headers['content-type'] === 'image/jpeg') {
            type = 'image';
          }
          const file = {type, blob: result.data, filename: ocrFile.Filename};
          this.files.push(file);
        }).catch(e => {
          this.notifications.handleAPIError(e);
        });
      }
    });
  }

  reset() {
    this.loadStats();
    this.job = null;
    this.files = [];
    this.SelectedOption = null;
    this.SelectOptions = null;
    this.SelectOptionsFiltered = null;
    this.hideSubmitButtons = false;
    this.loading = false;
  }

  loadStats() {
    this.documentPipelineService.transform.transformRGSClassifierGetStatsInput().then(stats => {
      this.jobCount = stats.data.Data.JobCount;
    }).catch(e => {
      if (typeof e.response.data === 'object' && e.response.data.ErrorCode === 500001 && e.response.data.ErrorMessage === 'No companies found') {
        this.notificationService.error('You don\'t have access to any company to perform RGS Classify jobs. You have been logged out.', null, {
          timeout: 0,
        });
        this.logoutUser();
        return;
      }

      this.notifications.handleAPIError(e);
    });
  }

  submitJob(loadNext = true) {
    this.hideSubmitButtons = true;

    let selectedOption = '';
    if (this.SelectedOption) {
      selectedOption = this.SelectedOption;
    }

    this.documentPipelineService.transform.transformRGSClassifierSubmitInput(this.job.Job.ID, {RGS: {SelectedOption: selectedOption}}).then(r => {
      this.notificationService.success(this.getTranslation(_('The job has successfully been submitted.')));
      if (loadNext) {
        this.loadJob();
      } else {
        this.reset();
      }
    }).catch(e => {
      this.notifications.handleAPIError(e);

      // When job was not claimed by this user, automatically load next.
      if (typeof e.response.data === 'object' && e.response.data.ErrorCode === 100015 && e.response.data.ErrorMessage === 'Job not claimed by this user') {
        if (loadNext) {
          this.loadJob();
        } else {
          this.reset();
        }
      }
    });
  }

  skipReview() {
    this.documentPipelineService.transform.transformRGSClassifierSkipInput(this.job.Job.ID).then(r => {
      this.notificationService.success(this.getTranslation(_('The job has successfully been skipped.')));
      this.loadJob();
    }).catch(e => {
      this.notifications.handleAPIError(e);
    });
  }

  logoutUser() {
    this.router.navigate(['/login']).then(() => {
      this.documentPipelineService.tokenStorage.setKey(null);
      this.documentPipelineService.tokenStorage.setSecret(null);
      this.documentPipelineService.tokenStorage.setRefreshToken(null);
      this.store.dispatch(new Logout());
      this.notificationService.success('Log out succesful.');
    });
  }

  trackSelectOption(item: any): any {
    return item.Key;
  }

  getManualActionLevelBadge(level: string): string {
    if (level === 'lead') {
      return 'danger';
    }
    return 'warning';
  }
}
