import {Component, Input, OnInit} from '@angular/core';
import {_} from '../../../util/i18n';

@Component({
  selector: 'app-empty-view',
  templateUrl: './empty-view.component.html',
})
export class EmptyViewComponent implements OnInit {
  @Input() public text = _('No items available.');
  @Input() public subtitle;
  @Input() public gif: '1' | '2' | '3' | '4' | '5' = '1';

  constructor() { }

  ngOnInit() {
  }

}
