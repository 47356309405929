import hmacSHA512 from 'crypto-js/hmac-sha512';
import {LocalStorageService} from 'angular-2-local-storage';



export interface AuthHeaders {
    'X-Auth-Key': string;
    'X-Auth-Timestamp': string;
    'X-Auth-Nonce': string;
    'X-Auth-Signature': string;
}

interface TokenStorage {
    refreshPromise?: Promise<any>;
    getKey(): string;
    getSecret(): string;
    getRefreshToken(): string;
    setKey(key: string): void;
    setSecret(secret: string): void;
    setRefreshToken(refreshToken: string): void;
}

export class LocalTokenStorage implements TokenStorage {
    protected prefix: string;

    constructor(protected localStorageService: LocalStorageService, prefix: string) {
        this.prefix = prefix;
    }

    public getKey(): string {
        return this.localStorageService.get<string>(this.storageKey('key'));
    }

    public getRefreshToken(): string {
        return this.localStorageService.get<string>(this.storageKey('refreshToken'));
    }

    public getSecret(): string {
        return this.localStorageService.get<string>(this.storageKey('secret'));
    }

    public setKey(key: string): void {
        this.localStorageService.set(this.storageKey('key'), key);
    }

    public setRefreshToken(refreshToken: string): void {
      this.localStorageService.set(this.storageKey('refreshToken'), refreshToken);
    }

    public setSecret(secret: string): void {
        this.localStorageService.set(this.storageKey('secret'), secret);
    }

    protected storageKey = (label) => this.prefix.toString() + label;
}

export class AuthManager {
    protected tokenStorage: TokenStorage;

    constructor(tokenStorage: TokenStorage) {
        this.tokenStorage = tokenStorage;
    }

    public getAuthHeaders(): AuthHeaders {
        const key = this.tokenStorage.getKey();
        const secret = this.tokenStorage.getSecret();
        if (key == null || secret == null) {
          return null;
        }
        const timeStamp = (Math.round((new Date()).getTime() / 1000)).toString();
        const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let nonce = '';
        for (let i = 0; i < 16; i++) {
            const randomChar = Math.floor(Math.random() * charSet.length);
            nonce += charSet.substring(randomChar, randomChar + 1);
        }
        const signature = hmacSHA512(timeStamp + nonce, secret).toString();
        return {
            'X-Auth-Key': key,
            'X-Auth-Nonce': nonce,
            'X-Auth-Signature': signature,
            'X-Auth-Timestamp': timeStamp,
        };
    }

    public getTokenStorage(): TokenStorage {
        return this.tokenStorage;
    }

    public setTokenStorage(tokenStorage: TokenStorage) {
        this.tokenStorage = tokenStorage;
    }

}
