import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Currency} from '../../../api/document-pipeline';

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
})
export class AmountInputComponent {
  @Input()
  public amount: number;

  @Input()
  public Currencies?: { [key: string]: Currency; };

  @Input()
  public currency: string;

  @Output()
  public amountChange: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public newValue: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  private firstRun = true;

  get amountValue() {
    if (this.amount) {
      return this.amount / 100;
    }
    return undefined;
  }

  set amountValue(val) {
    if (val && typeof val === 'string') {
      // @ts-ignore
      val = val.replace(',', '.');
    }
    let newVal = Math.round(val * 100);
    if (isNaN(newVal) && this.firstRun === false) {
      newVal = 0;
    }
    this.firstRun = false;
    this.amount = newVal;
    this.amountChange.emit(newVal);
    this.newValue.emit(true);
  }

  getCurrencyTitleOrSymbol() {
    if (this.Currencies && this.Currencies[this.currency]) {
      if (this.Currencies[this.currency].Symbol !== '') {
        return this.Currencies[this.currency].Symbol;
      }

      return this.currency;
    }
  }
}
