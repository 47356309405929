import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../base/base.component';
import {Observable} from 'rxjs';
import {Currency} from '../../../api/document-pipeline';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
})
export class AmountComponent extends BaseComponent implements OnInit {
  @Input() currency: string;
  @Input() amount: number;
  currencyObject: Observable<Currency>;

  ngOnInit() {
    if (this.currency) {
      //this.currencyObject = this.store.select('generic').pipe(select(fromGeneric.getCurrencyByCode(this.currency)));
    }
  }
}
