import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileViewerComponent} from './file-viewer/file-viewer.component';
import {FormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {NgSelectModule} from '@ng-select/ng-select';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import {ImageViewerModule} from '@hallysonh/ngx-imageviewer';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../helpers/pipes/pipes.module';
import { LoaderComponent } from './loader/loader.component';
import { CurrencyPickerComponent } from './currency-picker/currency-picker.component';
import {PercentageInputComponent} from './percentage-input/percentage-input.component';
import {AmountInputComponent} from './amount-input/amount-input.component';
import {VATInputComponent} from './vat-input/vat-input.component';
import {DatetimePickerComponent} from './datetime-picker/datetime-picker.component';
import {PaymentMethodPickerComponent} from './payment-method-picker/payment-method-picker.component';
import {AutofocusDirective} from './auto-focus/auto-focus.directive';
import {AmountComponent} from './amount/amount.component';
import {EmptyViewComponent} from './empty-view/empty-view.component';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {DateFormatComponent} from './date-format/date-format.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    PdfJsViewerModule,
    ImageViewerModule,
    TranslateModule,
    PipesModule,
    MomentModule
  ],
  declarations: [
    FileViewerComponent,
    LoaderComponent,
    CurrencyPickerComponent,
    CurrencyPickerComponent,
    AmountInputComponent,
    PercentageInputComponent,
    FileViewerComponent,
    VATInputComponent,
    DatetimePickerComponent,
    PaymentMethodPickerComponent,
    AutofocusDirective,
    AmountComponent,
    EmptyViewComponent,
  ],
  exports: [
    LoaderComponent,
    CurrencyPickerComponent,
    AmountInputComponent,
    PercentageInputComponent,
    FileViewerComponent,
    VATInputComponent,
    DatetimePickerComponent,
    PaymentMethodPickerComponent,
    AutofocusDirective,
    AmountComponent,
    EmptyViewComponent
  ],
  entryComponents: [
  ],
  providers: [{provide: NgbDateParserFormatter, useClass: DateFormatComponent}]
})
export class UiModule {
}
