import { Action } from '@ngrx/store';
import {User} from '../../../api/document-pipeline';

export enum UserActionTypes {
  LoginAction = '[Login] Action',
  LogoutAction = '[Logout] Action',
  UpdateAction = '[Update] Action',
  UpdateKey = '[Update key] Action',
}

export class Login implements Action {
  readonly type = UserActionTypes.LoginAction;
  constructor(public payload: {user: User}) {}
}

export class Update implements Action {
  readonly type = UserActionTypes.UpdateAction;
  constructor(public payload: {user: User}) {}
}

export class Logout implements Action {
  readonly type = UserActionTypes.LogoutAction;
}

export class UpdateKey implements Action {
  readonly type = UserActionTypes.UpdateKey;
  constructor(public payload: {key: string, secret: string}) {}
}

export type UserActions = Login | Update | Logout | UpdateKey;
