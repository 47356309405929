import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/unauthenticated/login/login.component';
import {CurrentUserResolve} from './modules/user/models/user.resolver.currentUser';
import {ClassifyComponent} from './pages/authenticated/classify/classify.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'classify',
    component: ClassifyComponent,
    resolve: {
      currentUser: CurrentUserResolve,
    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CurrentUserResolve],
})
export class AppRoutingModule { }
