import {
  Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit, ViewEncapsulation
} from '@angular/core';
import moment from 'moment-es6';
import {_} from '../../../util/i18n';

declare var $: any;

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
})

export class DatetimePickerComponent implements OnInit, OnChanges {
    @Input()
    public defaultDate: any = false;
    @Input()
    public startDate: any = false;
    @Input()
    public endDate: any = false;

    @Input()
    public type = 'date';

    @Input()
    public format = 'DD-MM-YYYY';
    @Input()
    public placeholder = _('Select date');
    @Input()
    public minDate = false;
    @Input()
    public maxDate = false;
    @Input()
    public useCurrent = true;

    @Input()
    public opens = 'right';

    @Input()
    public dateLimit = false;

    @Output()
    public onChanged = new EventEmitter<any>();

    @ViewChild('picker') pickerRef;
    private picker;

    public changed = false;

    ngOnInit(): void {
        this.initPicker();
    }

    initPicker() {
        const icons = {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: '',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
        };

        this.picker = $(this.pickerRef.nativeElement);

        if (this.type === 'range') {
            this.picker.daterangepicker({
                locale: {
                    format: this.format
                },
                startDate: this.startDate,
                endDate: this.endDate,
                minDate: this.minDate,
                maxDate: this.maxDate,
                alwaysShowCalendars: true,
                buttonClasses: 'btn btn-sm btn-block',
                applyClass: 'btn-primary btn-fill',
                autoApply: true,
                dateLimit: this.dateLimit,
                opens: this.opens,
                timePicker: true,
                timePicker24Hour: true,
                parentEl: 'body',
            }).on('apply.daterangepicker',  (ev, picker) => {
                this.changed = true;
                this.onChanged.emit({start: picker.startDate, end: picker.endDate});
            });
        } else {
            this.picker.datetimepicker({
                defaultDate: this.defaultDate,
                format: this.format,
                icons: icons,
                minDate: this.minDate,
                maxDate: this.maxDate,
                locale: moment.locale(),
                useCurrent: this.useCurrent
            }).on('dp.change', (e) => {
                this.changed = true;
                this.onChanged.emit(e.date);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.picker && changes.defaultDate) {
            if (typeof changes.defaultDate.currentValue !== 'boolean') {
              this.picker.data('DateTimePicker').date(changes.defaultDate.currentValue);
            }
        }

        if (this.picker && changes.startDate) {
            this.picker.data('daterangepicker').setStartDate(changes.startDate.currentValue);
        }

        if (this.picker && changes.endDate) {
            this.picker.data('daterangepicker').setEndDate(changes.endDate.currentValue);
        }
    }

}
