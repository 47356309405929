import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../services/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {environment} from '../../environments/environment';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Login, UpdateKey} from '../modules/user/models/user.actions';
import {_} from '../util/i18n';
import {DocumentPipelineService} from '../api/document-pipeline/service';
import {InlineResponse20032, InlineResponse20049} from '../api/document-pipeline';
import {APINotificationsService} from '../api/services/apinotifications.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit, OnDestroy {
  static injector: Injector;
  protected notificationService: NotificationService;
  protected store: Store<AppState>;
  public environment: any;
  protected formBuilder: FormBuilder;
  protected translate: TranslateService;
  protected router: Router;
  protected documentPipelineService: DocumentPipelineService;
  protected notifications: APINotificationsService;
  protected destroyCallbacks = [];

  constructor() {
    this.translate = BaseComponent.injector.get(TranslateService);
    this.notificationService = BaseComponent.injector.get(NotificationService);
    this.store = BaseComponent.injector.get(Store);
    this.formBuilder = BaseComponent.injector.get(FormBuilder);
    this.router = BaseComponent.injector.get(Router);
    this.documentPipelineService = BaseComponent.injector.get(DocumentPipelineService);
    this.notifications = BaseComponent.injector.get(APINotificationsService);
    this.environment = environment;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroyCallbacks.forEach(cb => cb());
  }

  getTranslation(str: string) {
    return this.translate.instant(str);
  }

  finalizeLogin(r: InlineResponse20049) {
    this.documentPipelineService.tokenStorage.setKey(r.Data.Key.Key);
    this.documentPipelineService.tokenStorage.setSecret(r.Data.Key.Secret);
    this.documentPipelineService.tokenStorage.setRefreshToken(r.Data.RefreshToken);

    const userPayload = r.Data.Key.User;
    this.store.dispatch(new Login({user: userPayload}));


    this.notificationService.success(this.translate.instant(_('Welcome to %companyName%!'), {companyName: environment.company_name}));
    this.router.navigate(['/classify']);
  }
}
