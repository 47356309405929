import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {_} from '../../../util/i18n';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payment-method-picker',
  templateUrl: './payment-method-picker.component.html',
})
export class PaymentMethodPickerComponent implements OnInit {
  public paymentMethods = [];
  public paymentMethodValue: string;

  @Output()
  public paymentMethodChange = new EventEmitter<string>();

  @Input() public disabled = false;
  constructor(protected translate: TranslateService) {
    this.paymentMethods = [
      {
        Title: this.translate.instant(_('Bank')),
        Code: 'bank',
      },
      {
        Title: this.translate.instant(_('Cash')),
        Code: 'cash',
      },
      {
        Title: this.translate.instant(_('CreditCard')),
        Code: 'creditcard',
      },
      {
        Title: this.translate.instant(_('Not paid')),
        Code: 'not_paid',
      },
      {
        Title: this.translate.instant(_('Other')),
        Code: 'other',
      },
      {
        Title: this.translate.instant(_('PIN')),
        Code: 'pin',
      },
      {
        Title: this.translate.instant(_('Private')),
        Code: 'private',
      }
    ];
  }

  ngOnInit() {
  }

  @Input()
  get paymentMethod() {
    return this.paymentMethodValue;
  }

  set paymentMethod(val) {
    this.paymentMethodValue = val;
    this.paymentMethodChange.emit(this.paymentMethodValue);
  }

}
