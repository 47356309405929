import {InformationApi, JobsApi, OutputApiApi, PipelinesApi, TransformApi, UserApi} from './api';
import {Configuration} from './configuration';
import {AuthManager, LocalTokenStorage} from './auth';
import {Injectable} from '@angular/core';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class DocumentPipelineService {
    public tokenStorage: LocalTokenStorage;
    protected configuration: Configuration;
    public information: InformationApi;
    public user: UserApi;
    public jobs: JobsApi;
    public output: OutputApiApi;
    public pipelines: PipelinesApi;
    public transform: TransformApi;


    constructor(public localStorageService: LocalStorageService) {
        this.tokenStorage = new LocalTokenStorage(localStorageService, 'documentPipeline');
        const authManager = new AuthManager(this.tokenStorage);

        this.configuration = new Configuration({authManager});

        this.information = new InformationApi(this.configuration);
        this.user = new UserApi(this.configuration);
        this.jobs = new JobsApi(this.configuration);
        this.output = new OutputApiApi(this.configuration);
        this.pipelines = new PipelinesApi(this.configuration);
        this.transform = new TransformApi(this.configuration);
    }
}

